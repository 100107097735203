import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { GiMicrophone } from 'react-icons/gi';
import { FaRegPlayCircle } from 'react-icons/fa';
import { ASSETS } from '../assets/assets';

const MicrophoneFeatures = () => {
  return (
    <Container fluid style={{ backgroundColor: '#B8E8D6' }} className="py-5">
      <Container>
        <h2 className="text-center mb-4" style={{ color: '#0A6046' }}>
          Recursos do Microfone
        </h2>
        <Row className="align-items-center">
          <Col md={6} className="text-center">
            <img
              src={ASSETS.microphone}
              alt="Microfone"
              className="img-fluid mb-4"
              style={{ maxWidth: '300px' }}
            />
          </Col>
          <Col md={6}>
            <h4 style={{ color: '#0F805D' }}>
              <GiMicrophone className="mr-2" />
              Reconhecimento de Voz
            </h4>
            <p>
              O aplicativo "Fala Comigo, IA" permite que você fale no microfone e converta sua voz em texto. Basta falar e deixar o aplicativo fazer o resto.
            </p>
            <h4 style={{ color: '#0F805D' }}>
              <FaRegPlayCircle className="mr-2" />
              Reprodução de Áudio
            </h4>
            <p>
              Além de converter a voz em texto, o aplicativo também reproduz o áudio da resposta, proporcionando uma experiência de conversa mais envolvente e interativa.
            </p>
            <h4 style={{ color: '#0F805D' }}>
              <GiMicrophone className="mr-2" />
              Modo Mãos Livres
            </h4>
            <p>
              O aplicativo oferece um modo "mãos livres" que permite uma conversa contínua com a IA. Quando ativado, o microfone é reaberto automaticamente após a resposta da IA, permitindo que você continue a conversa sem precisar tocar no dispositivo.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default MicrophoneFeatures;
