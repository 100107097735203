import React, { useEffect } from "react";

const TermsOfUse = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ 
        textAlign: 'justify',
        padding: '14px',
        }}>
      <h1>Termos de Uso do Aplicativo "Fala Comigo, IA"</h1>
      <p>Última atualização: Maio/2023</p>

    

      <h3>1. Aceitação dos Termos de Uso</h3>
      <p>
      Ao acessar ou utilizar o aplicativo "Fala Comigo, IA" ("Aplicativo"), você, o usuário ("Usuário"), concorda em cumprir e ficar vinculado a estes Termos de Uso ("Termos"). Se você não concordar com estes Termos, não acesse nem utilize o Aplicativo.
      </p>

      <h3>2. Descrição do Aplicativo</h3>
      <p>
      O Aplicativo é uma plataforma de entretenimento que permite aos Usuários interagir com a inteligência artificial ChatGPT, fornecida pela OpenAI. O Aplicativo permite que os Usuários selecionem assuntos e conversem com a inteligência artificial sobre tais assuntos.
      </p>

      <h3>3. Licença de Uso</h3>
      <p>
      O Aplicativo concede ao Usuário uma licença limitada, não exclusiva, intransferível e revogável para acessar e utilizar o Aplicativo para fins pessoais e não comerciais, conforme permitido por estes Termos.
      </p>

      <h3>4. Responsabilidade do Usuário</h3>
      <p>
      O Usuário é inteiramente responsável pelo conteúdo de suas comunicações com a inteligência artificial ChatGPT e por garantir que essas comunicações estejam em conformidade com todas as leis e regulamentações aplicáveis. O Usuário concorda em não utilizar o Aplicativo para fins ilegais, difamatórios, obscenos, ofensivos, abusivos, discriminatórios ou fraudulentos.
      </p>

      <h3>5. Armazenamento de Dados</h3>
      <p>
      O Aplicativo não armazena nem transmite as conversas do Usuário para servidores ou terceiros. Todas as conversas são armazenadas exclusivamente no dispositivo móvel do Usuário. O Aplicativo não é responsável pela perda, corrupção ou acesso não autorizado a tais conversas.
      </p>

      <h3>6. Relação com a OpenAI</h3>
      <p>
      O Aplicativo utiliza a inteligência artificial ChatGPT fornecida pela OpenAI, uma entidade terceira. O Aplicativo não é responsável pela funcionalidade, precisão, disponibilidade ou confiabilidade da inteligência artificial ChatGPT. O Usuário reconhece e concorda que a OpenAI é responsável exclusivamente pela prestação e manutenção da inteligência artificial ChatGPT, e o Aplicativo não tem controle nem responsabilidade sobre a mesma.
      </p>

      <h3>7. Isenção de Responsabilidade</h3>
      <p>
      O Aplicativo é fornecido "no estado em que se encontra", sem garantias de qualquer tipo, expressas ou implícitas. O Aplicativo se isenta de qualquer responsabilidade por danos diretos, indiretos, incidentais, especiais, punitivos ou consequentes, incluindo, mas não se limitando a, perda de lucros, dados, uso ou interrupção de negócios, decorrentes do uso ou da incapacidade de usar o Aplicativo ou da interação do Usuário com a inteligência artificial ChatGPT.
      </p>

      <h3>8. Modificações nos Termos de Uso</h3>
      <p>
      O Aplicativo se reserva o direito de modificar estes Termos a qualquer momento, a seu exclusivo critério. Se os Termos forem alterados, o Aplicativo notificará o Usuário por meio de uma atualização do Aplicativo ou de outra forma que considere apropriada. O uso contínuo do Aplicativo após a notificação de tais alterações constituirá aceitação dos Termos modificados.
      </p>

      <h3>9. Legislação Aplicável e Jurisdição</h3>
      <p>
      Estes Termos serão regidos e interpretados de acordo com as leis do país em que o proprietário do Aplicativo está sediado, sem consideração aos conflitos de princípios legais. O Usuário concorda em submeter-se à jurisdição exclusiva dos tribunais do país em que o proprietário do Aplicativo está sediado, para a resolução de qualquer disputa decorrente destes Termos ou do uso do Aplicativo.
      </p>
      
      <h3>10 Disposições Gerais</h3>
      <p>
      Se alguma disposição destes Termos for considerada inválida ou inexequível, tal disposição será considerada modificada na medida mínima necessária para torná-la válida e exequível, e as outras disposições destes Termos permanecerão em pleno vigor e efeito. A falha do Aplicativo em exercer ou aplicar qualquer direito ou disposição destes Termos não constituirá uma renúncia a esse direito ou disposição. Estes Termos constituem o acordo integral entre o Usuário e o Aplicativo em relação ao uso do Aplicativo e substituem todos os acordos anteriores, escritos ou verbais, entre o Usuário e o Aplicativo em relação ao Aplicativo.
      </p>
    </div>
  );
};

export default TermsOfUse;
