import React, { useEffect } from 'react';

const PrivacyPolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div style={{ 
        textAlign: 'justify',
        padding: '14px',
        }}>
      <h1>Política de privacidade do "Fala Comigo, IA"</h1>
      <p>Última atualização: Maio/2023</p>

      <p>
        Esta Política de Privacidade descreve como o aplicativo "Fala Comigo,
        IA" (doravante referido como "Aplicativo") coleta, armazena, utiliza e
        protege as informações pessoais dos usuários (doravante referidos como
        "Usuários"). Ao utilizar o Aplicativo, você concorda com a coleta e uso
        de informações de acordo com esta Política.
      </p>

      <h3>Informações que coletamos</h3>
      <p>
        O Aplicativo coleta informações mínimas dos Usuários para fornecer o
        serviço. As informações coletadas incluem:
      </p>
      <ul>
        <li>
          Informações fornecidas pelo Usuário: O Aplicativo pode solicitar
          informações, como nome de usuário ou e-mail, para criar uma conta ou
          fornecer suporte ao Usuário.
        </li>
        <li>
          Informações geradas pelo Usuário: As conversas entre o Usuário e a
          inteligência artificial ChatGPT são armazenadas localmente no
          dispositivo do Usuário e não são enviadas aos servidores do
          Aplicativo. Entretanto, essas informações são enviadas para os
          servidores da OpenAI para processamento e geração de respostas.
        </li>
      </ul>

      <h3>Como utilizamos as informações</h3>
      <p>As informações coletadas são utilizadas para:</p>
      <ul>
        <li>Fornecer, manter e melhorar o Aplicativo e seus serviços.</li>
        <li>
          Comunicar-se com o Usuário sobre atualizações, promoções e suporte ao
          cliente.
        </li>
      </ul>

      <h3>Compartilhamento de informações</h3>
      <p>
        O Aplicativo não compartilha as informações pessoais dos Usuários com
        terceiros, exceto quando necessário para fornecer os serviços do
        Aplicativo, como no caso do processamento das conversas com a
        inteligência artificial ChatGPT pela OpenAI.
      </p>

      <h3>Segurança das informações</h3>
      <p>
        O Aplicativo adota medidas razoáveis para proteger as informações
        pessoais dos Usuários contra acesso não autorizado, divulgação ou
        destruição. No entanto, nenhum método de transmissão ou armazenamento
        eletrônico é 100% seguro e, portanto, não podemos garantir a segurança
        absoluta das informações.
      </p>

      <h3>Retenção de dados</h3>
      <p>
        As informações pessoais dos Usuários serão retidas pelo tempo necessário
        para fornecer os serviços do Aplicativo e cumprir com obrigações legais,
        resolver disputas e aplicar nossos acordos.
      </p>

      <h3>Mudanças nesta Política de Privacidade</h3>
      <p>
        O Aplicativo se reserva o direito de modificar esta Política de
        Privacidade a qualquer momento. Se houver alterações nesta Política, o
        Aplicativo notificará os Usuários por meio de uma atualização do
        Aplicativoou de outra forma que considere apropriada. O uso contínuo do
        Aplicativo após a notificação de tais alterações constituirá aceitação
        da Política de Privacidade modificada.
      </p>
      <h3>Contato</h3>
      <p>
        Se você tiver dúvidas ou preocupações sobre esta Política de
        Privacidade, entre em contato conosco através do e-mail: ismartapp.ai@gmail.com.
      </p>

      <p>
        Ao utilizar o Aplicativo, você concorda com esta Política de
        Privacidade. Se você não concordar com esta política, por favor, não
        utilize o Aplicativo. Seu uso contínuo do Aplicativo após a publicação
        de alterações nesta política será considerado como sua aceitação dessas
        alterações.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
