import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Download from './components/Download';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import './index.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import MicrophoneFeatures from './components/MicrophoneFeatures';
import Avatars from './components/Avatars';
import TermsOfUse from './components/TermsOfUse';


const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<><Hero /><Features /><MicrophoneFeatures /> <Avatars /> <Download /></>} />
        <Route path="/download" element={<Download />} />
        <Route path="/privacidade" element={<PrivacyPolicy />} />
        <Route path="/termos-de-uso" element={<TermsOfUse />} />
      </Routes>
      <Footer />
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
