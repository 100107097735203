import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { ASSETS } from '../assets/assets';

const Header = () => {
  const handleClick = () => {
    window.location.href = '/';
  };

  return (
    <Navbar
      style={{
        backgroundColor: '#0F805D',
      }}
      variant="dark"
      expand="lg"
    >
      <Container>
        <Navbar.Brand onClick={handleClick}>
          <img
            src={ASSETS.final_logo_main}
            alt="Fala Comigo, IA"
            style={{
              borderRadius: '50%',
              marginRight: '10px',
              cursor: 'pointer',
            }}
            width="40"
            height="40"
          />
          Fala Comigo, IA
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default Header;
