import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaAndroid, FaMobileAlt, FaDownload } from 'react-icons/fa';

const DownloadApp = () => {
  return (
    <Container fluid className="bg-light py-5">
      <Container>
        <h2 className="text-center mb-4">Baixe o Fala Comigo, IA para Android!</h2>
        <Row>
          <Col md={6} className="d-flex flex-column justify-content-center">
            <h4 className="text-center">Transforme seu dispositivo Android em uma máquina de conversas!</h4>
            <p className="text-center">
              Baixe o aplicativo Fala Comigo, IA e divirta-se com conversas envolventes e personalizadas. Escolha entre avatares, participe de diálogos contínuos e converse com especialistas em diversos temas, tudo diretamente do seu dispositivo Android.
            </p>
            <div className="d-flex justify-content-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.castelia.ismart"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-success px-4 py-2 font-weight-bold d-flex align-items-center"
              >
                <FaDownload className="mr-2" />
                Baixar Agora
              </a>
            </div>
          </Col>
          <Col md={6} className="text-center">
            <FaAndroid size={120} color="#3DDC84" className="mb-3" />
            <h5>Disponível apenas para Android</h5>
            <p>
              O Fala Comigo, IA foi desenvolvido exclusivamente para dispositivos Android. Aproveite o melhor da inteligência artificial em seu smartphone ou tablet.
            </p>
            <FaMobileAlt size={120} className="mt-3" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default DownloadApp;
