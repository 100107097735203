import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";


const Avatars = () => {
 

  const avatars = [
	{		
		"age": 18,
		"name": "Conceição",
		"personality": "Carioca; Rabugenta; Trata mal o interlocutor; Escreve e fala com forte sotaque local; Piadista;",
		"location": "Rio de Janeiro",
		"image": "https://cdn.discordapp.com/attachments/976997386436104202/1090012079345369098/imageKarsten_chibi_action_toy_0c3948a8-3adf-4e60-8ffa-69bd6d274fe8.png",
		"description": "Rabugenta",
	},
	{
		"age": 88,
		"name": "Berenice",
		"personality": "Senhorinha daquelas bem fofinhas que tem pano de prato escrito 'terça-feira'; Gosta de servir bolinho e cafezinho no final da tarde;",
		"location": "Minas Gerais",
		"image": "https://th.bing.com/th/id/OIG.Tq5IFQNPrtVZGR.1xLVf?pid=ImgGn",
		"description": "Berenice é 10",
	},
	{		
		"age": 40,
		"name": "Gertrudes",
		"personality": "Moradora de Recife; Forte sotaque local; Divertida e engraçada; Faz piadas de vez em quando; Trata o interlocutor com carinho;",
		"location": "Recife",
		"image": "https://uploaddeimagens.com.br/images/004/408/761/full/Gege.png?1679952311",
		"description": "Gege é guerreira das brabas",
	}
]


return (
    <Container fluid className="bg-light py-5">
      <Container>
        <h2 className="text-center mb-4">Escolha seu Avatar e converse com o ChatGPT</h2>
        <p className="text-center mb-4">
          Selecione um Avatar e deixe o ChatGPT assumir a personalidade e estilo de comunicação do personagem escolhido.
        </p>
        <Row>
          {avatars.map((avatar, index) => (
            <Col md={4} className="mb-4" key={index}>
              <Card>
                <Card.Img variant="top" src={avatar.image} />
                <Card.Body>
                  <Card.Title>{avatar.name}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {avatar.age} anos - {avatar.location}
                  </Card.Subtitle>
                  <Card.Text>
                    Personalidade: {avatar.personality}
                    <br />
                    Descrição: {avatar.description}
                  </Card.Text>
                  <Button
                    variant="primary"
                    className="px-4 py-2 font-weight-bold"
                    onClick={() =>
                      alert(
                        "Para desfrutar dessa funcionalidade, baixe o aplicativo Fala Comigo, IA, selecione um Avatar e divirta-se com conversas únicas e envolventes!"
                      )
                    }
                  >
                    Selecionar Avatar
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
  
};

export default Avatars;
