import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ASSETS } from "../assets/assets";


const Hero = () => {
  return (
    <Container fluid className="text-white py-5" style={{background: "#B8E8D6"}}>
      <Container>
        <Row>
          <Col md={6} className="d-flex flex-column justify-content-center">
            <h1 style={{color: '#0A6046'}}>Fala Comigo, IA</h1>
            <p style={{color: '#0A6046'}}>
              Explore diferentes personalidades e converse com nossa IA avançada, o ChatGPT. Escolha entre diálogos contínuos, conversas com especialistas e experiências de role-play para desfrutar de conversas envolventes e personalizadas.
            </p>
          </Col>
          <Col md={6} className="text-center">
            <img src={ASSETS.final_logo_main} alt="Fala Comigo, IA logo" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Hero;
