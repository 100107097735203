import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';


import './Features.css';
import { ASSETS } from '../assets/assets';

const Features = () => {
  return (
    <Container fluid className="bg-light py-5">
      <Container>
        <h2 className="text-center mb-4">Principais Funcionalidades</h2>
        <Row>
          <Col className="text-center">
            <Carousel interval={3000} fade={true}>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={ASSETS.continuous_dialogue}
                  alt="Diálogo Contínuo"
                />
                <Carousel.Caption className="caption-bg">
                  <h4>Diálogo Contínuo</h4>
                  <p>Selecione uma categoria e subcategoria para iniciar conversas envolventes e contínuas com a IA sobre temas específicos.</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={ASSETS.expert_conversation}
                  alt="Conversa com Especialista"
                />
                <Carousel.Caption className="caption-bg">
                  <h4>Conversa com Especialista</h4>
                  <p>Obtenha orientações de especialistas em diversos temas, selecionando uma categoria e subcategoria para que a IA assuma o papel de um especialista no assunto.</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={ASSETS.role_play}
                  alt="Role-play"
                />
                <Carousel.Caption className="caption-bg">
                  <h4>Role-play</h4>
                  <p>Participe de experiências de role-play guiadas pela IA, que propõe cenários e possibilidades interessantes para você explorar e se envolver.</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={ASSETS.menu}
                  alt="Menu do Aplicativo"
                />
                <Carousel.Caption className="caption-bg">
                  <h4>Menu do Aplicativo</h4>
                  <p>Acesse todas as funcionalidades incríveis do aplicativo a partir do menu principal.</p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Features;
